.App {
  text-align: center;
}
.project{
  margin-top: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-transform: capitalize;
}

.projectComponent{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.projectRow{
  width: 19%;
  display:flex;
  flex-direction: column;
  align-items: center;
  border: solid lavender;
  padding: 15px;
  margin-top: 20px;
}

.projectTitle{
  width: 60%;
  font-weight: 900;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.tasks{
  margin-top:10px;
  padding-top: 5px;
  border-top: solid blanchedalmond 2px;
}

.taskName{
  font-style: italic;
  font-weight: 500;
}

.taskDescription{

}

.addButton{
  margin-top: 10px;
  background-color: #03a9f4;
  border-radius: 25px;
  color: whitesmoke;
  font-weight: 500;
  padding: 6px 10px;
  width: auto;
}

.taskForm{
  border: solid black 5px;
  width: 50%;
  margin: 10px;
  margin-left: 24%;
  padding: 5px;
}

label{
  margin: 5px;
}

.form{
  margin: 5px;
}
.editButton{
  font-size: medium;
}

.deleteButton{
  color: red;
  align-self: flex-end;
}
.deleteButton:hover{
  color: darkred;
}